$lfx-dark-blue: #002447;
$lfx-blue: #036;
$lfx-light-blue: #09c;
$lfx-success: #8bc34a;
$lfx-warning: #ffad5f;
$lfx-grey: #8492a6;
$lfx-light-grey: #d9e0e7;
$lfx-dark-grey: #333;
$lfx-smooth-light-grey: #6e6e6e;
$lfx-red: #f44336;
$lfx-disabled: #bdbdbd;
$lfx-lighter-blue: #f5faff;
$lfx-white: #fff;
