@import 'colors';

@mixin font-size($size) {
  font-size: $size;
  font-size: calculaterem($size);
}

@mixin push--auto($vertically: false) {
  @if $vertically {
    margin: {
      top: $vertically;
      bottom: $vertically;
      left: auto;
      right: auto;
    }
  } @else {
    margin: {
      left: auto;
      right: auto;
    }
  }
}

@mixin font-roboto($size: false, $colour: false, $weight: false, $lh: false) {
  font-family: 'Roboto', Helvetica, Arial, sans-serif;
  @if $size {
    font-size: $size;
  }
  @if $colour {
    color: $colour;
  }
  @if $weight {
    font-weight: $weight;
  }
  @if $lh {
    line-height: $lh;
  }
}

@mixin text-small-grey() {
  color: rgba(0, 0, 0, 0.54);
  font-size: 0.875rem;
  text-transform: none;
  text-decoration: none;
}

@mixin text-bold-grey() {
  color: $lfx-grey;
  font-weight: bold;
}

$spaceamounts: (0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85, 90, 95, 100); // Adjust this to include the pixel amounts you need.
$sides: (top, bottom, left, right); // all positions

@each $space in $spaceamounts {
  @each $side in $sides {
    .m#{str-slice($side, 0, 1)}-#{$space} {
      margin-#{$side}: #{$space}px !important;
    }

    .p#{str-slice($side, 0, 1)}-#{$space} {
      padding-#{$side}: #{$space}px !important;
    }
  }
}

@each $space in $spaceamounts {
  .m-#{$space} {
    margin: #{$space}px !important;
  }

  .p-#{$space} {
    padding: #{$space}px !important;
  }
}

$weightamounts: (100, 200, 300, 400, 500, 600, 700, 800, 900);

@each $weight in $weightamounts {
  .w#{$weight} {
    font-weight: #{$weight} !important;
  }
}
