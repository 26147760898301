// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@import '~@angular/material/theming';
// Plus imports for other components in your app.
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');
@import url('https://pro.fontawesome.com/releases/v5.15.2/css/all.css');

@import '~@swimlane/ngx-datatable/index.css';
@import '~@swimlane/ngx-datatable/themes/material.scss';
@import '~@swimlane/ngx-datatable/assets/icons.css';
@import '~ngx-toastr/toastr';
@import '~angular-calendar/css/angular-calendar.css';

@import 'mixins';

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$lfx-pcc-primary: mat-palette($mat-indigo);
/* stylelint-disable-next-line */
$lfx-pcc-accent: mat-palette($mat-pink, A200, A100, A400);

// The warn palette is optional (defaults to red).
$lfx-pcc-warn: mat-palette($mat-red);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$lfx-pcc-theme: mat-light-theme(
  (
    color: (
      primary: $lfx-pcc-primary,
      accent: $lfx-pcc-accent,
      warn: $lfx-pcc-warn
    )
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($lfx-pcc-theme);

$custom-typography: mat-typography-config(
  $font-family: 'Open Sans, sans-serif',
  $headline: mat-typography-level(32px, 48px, 700),
  $body-1: mat-typography-level(15px, 24px, 500)
);

@include mat-core($custom-typography);

$animation-speed: 0.3s;

html,
body {
  margin: 0;
  font-family: 'Open Sans', sans-serif;
  height: 100%;

  main {
    margin-top: 70px;
    transition: margin $animation-speed ease-in;

    *:focus {
      outline: 0 !important;
    }

    .main-container {
      position: relative;
      > * {
        > .page-header-container {
          margin-left: 275px;
          border-bottom: 1px solid #eeee;
          padding: 0;
          margin-right: 25px;
        }
        > .container,
        > app-loading {
          padding: 10px 20px;
          margin: 20px auto;
          margin-left: 250px;
        }
      }

      .bold {
        font-weight: bold;
      }

      .bold-fs-14 {
        font-weight: bold;
        font-size: 14px !important;
      }

      .grey {
        color: $lfx-grey;
      }
    }
  }

  &.lfx-main-header-expanded {
    main {
      margin-top: 70px;
    }
  }
}

.multiselect-panel {
  margin-top: 30px !important;
}

.toast-container {
  .toast-close-button {
    right: 0;
    top: 0;
    left: 0;
    color: #000;
  }

  .ngx-toastr {
    border: 1px solid #e0e0e0;
    box-shadow: none !important;
    border-radius: 6px;
  }

  div.toast-error {
    background-color: rgb(251, 219, 219) !important;

    .fa-exclamation-circle {
      color: $lfx-red;
    }

    .toast-title {
      display: none;
    }

    .toast-message {
      color: #000;

      &::before {
        content: '\f06a';
        font-family: 'Font Awesome 5 Pro', sans-serif !important;
        color: $lfx-red;
        font-weight: 900;
        margin-right: 5px;
      }

      a {
        color: $lfx-light-blue;

        &:hover {
          color: $lfx-blue;
        }
      }
    }
  }

  .toast-success {
    background: #ecf7e6 0% 0% no-repeat padding-box;

    .toast-title {
      display: none;
    }

    .toast-message {
      color: #000;

      &::before {
        content: '\f058';
        font-family: 'Font Awesome 5 Pro', sans-serif !important;
        color: $lfx-success;
        font-weight: 900;
        margin-right: 5px;
      }
    }
  }
}

.lfx-tooltip,
.tippy-box {
  background-color: #fff;
  color: $lfx-dark-grey;
  font-size: 11px;
  font-weight: normal;
  border: 1px solid $lfx-light-grey;
  word-break: break-word;
}

.pill {
  background: #fff;
  border: 1px solid #8492a6;
  border-radius: 6px;
  display: inline-block;
  padding: 3px 5px;
  font-size: 11px;
  font-weight: 700;
  margin-right: 10px;
}

.mat-checkbox-layout {
  white-space: normal !important;
}

@media (max-width: 1024px) {
  main {
    margin-top: 70px !important;
  }
}

.no-border {
  border-right: 0 !important;
}

/*** Hiding default chart legend ***/
.advanced-pie-legend-wrapper {
  display: none !important;
}
